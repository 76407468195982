"use client";

import * as React from "react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { User, Heart, ShoppingBag, LogOut, CircleCheckBig } from "lucide-react";
import MyLink from "../myLink/MyLink";
import { useTranslate } from "@/components/base/providers/TranslateProv";

export function DropdownMenuUser({ open: triggerElement, logout }: any) {
  const { locale } = useTranslate();
  const isRTL = locale === "ar";

  // Manage the open state of the dropdown
  const [isOpen, setIsOpen] = React.useState(false);

  const handleSelect = () => {
    setIsOpen(false); // Close the dropdown when an item is clicked
  };

  return (
    <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild>
        <div className="w-full cursor-pointer">{triggerElement}</div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className={`w-56 ${isRTL ? "text-right" : "text-left"}`}
        onClick={handleSelect} // Close dropdown when clicking inside the content
      >
        <div className="w-full">
          <ul className="bg-white rounded shadow-xl">
            <li onClick={handleSelect}>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : " flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2 `}
                href={`/profile?index=1`}
              >
                <span>{isRTL ? "البيانات الشخصية" : "Personal Data"}</span>
                <User size={24} />
              </MyLink>
            </li>
            <li onClick={handleSelect}>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : "flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2`}
                href={`/profile?index=2`}
              >
                <span>{isRTL ? "استشاراتي" : "My Consultations"}</span>
                <CircleCheckBig size={20} className="ml-2" />
              </MyLink>
            </li>
            <li onClick={handleSelect}>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : "flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2`}
                href={`/profile?index=3`}
              >
                <span>{isRTL ? "المفضلة" : "Favorites"}</span>
                <Heart size={20} className="ml-2" />
              </MyLink>
            </li>
            <li onClick={handleSelect}>
              <MyLink
                className={`flex items-center ${
                  isRTL ? "justify-end" : "flex-row-reverse justify-end"
                } gap-[10px] p-3 cursor-pointer hover:text-primaryColor hover:bg-[#E8F0FE] rounded mb-2`}
                href={`/profile?index=4`}
              >
                <span>{isRTL ? "طلباتي" : "My Orders"}</span>
                <ShoppingBag size={20} className="ml-2" />
              </MyLink>
            </li>

            <li
              onClick={() => {
                handleSelect(); // Close the dropdown
                logout(); // Call logout function
              }}
              className="flex items-center justify-center p-3 cursor-pointer hover:bg-red-100 text-red-500 mt-2"
            >
              <span>{isRTL ? "تسجيل الخروج" : "Log Out"}</span>
              <LogOut size={20} className="ml-2" />
            </li>
          </ul>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
